<template>
    <div class="container news-page">

        <div class="com-jiaxiao-crumbs">
            <div class="common-channel-crumb"><span>当前位置：</span><a>全国教练</a><i>&gt;</i><a>北京市教练</a><i>&gt;</i><strong>张立男</strong></div>
        </div>

        <div class="frame-l-r">
            <div class="left no-float">
                <div class="com-coach-base-info">
                    <div class="jiaxiao-content clearfix">
                        <div class="left img-w"><img src="https://jiaxiaozhijia.image.mucang.cn/jiaxiaozhijia/2017/03/31/17/fb62f43552ac40d583555bbd3c471495.png!180.180" alt="张立男"></div>
                        <div class="item-mid left">
                            <p class="top-bar"><span class="title">张立男</span><span class="renzheng"></span><span class="qianyue">7年教龄</span></p>
                            <p class="price">￥5600</p>
                            <div class="address"><span class="score-w">
                                    <div class="star-w">
                                        <div class="score" style="width: 98.00000000000001%"></div>
                                    </div><span class="score">4.9分</span>
                                </span><span class="student">6708名学员</span></div>
                            <p class="phone-w"><span>所属学校：<a>东方时尚驾校</a></span></p>
                            <div class="fenxiang-w">
                                <div class="share"><span class="label">分享：</span>
                                    <div class="inner">
                                        <div class="bdsharebuttonbox bdshare-button-style0-16"><a class="bds_tsina" title="分享到新浪微博"></a><a class="bds_qzone" title="分享到QQ空间"></a><a class="bds_weixin" title="分享到微信"></a></div>
                                    </div>
                                </div>
                            </div>
                        </div><a class="btn-base-enroll">免费咨询</a>
                    </div>
                </div>
                <div class="com-jiaxiao-nav com-part">
                    <ul class="clearfix">
                        <li class="active"><a>全部</a></li>
                        <li class=""><a>教练简介</a></li>
                        <li class=""><a>班型费用</a></li>
                        <li class=""><a>教学环境</a></li>
                        <li class=""><a>学员评价</a></li>
                        <li class=""><a>报名咨询</a></li>
                    </ul>
                </div>
                <div class="com-jiaxiao-introducea com-part">
                    <h3 class="part-title">教练简介</h3>
                    <div class="content">138-1149-0420找我报名办理手续快，不用排队！
                        VX:bjdfssjx
                        大家好，我是东方时尚驾校张立男老师。您学车期间有任何问题，都可以找我解决！
                        报名方式，坐驾校免费班车，来驾校报名即可！
                        东方时尚驾校位于大兴区金星路19号。北京市只有这一个训练场地！仅此一家！</div>
                </div>
                <div class="com-jiaxiao-price com-part">
                    <h3 class="part-title">教练班型费用</h3>
                    <p class="p3 red">*该价格仅供参考，具体报名费用请以驾校报名点价格表为准</p>
                    <ul class="list-w">
                        <li class="clearfix"><span class="col">课程名称</span><span class="col small">教学类型</span><span class="col">学车费用</span><span class="col col2">备注</span><span class="col last">报名方式</span></li>
                        <li class="clearfix"><span class="col">C1 普通班</span><span class="col">C1</span><span class="col red">￥5600</span><span class="col">班型的详细介绍和服务特色，请点击报名咨询按钮，向驾校咨询</span><span class="col"><a class="btn">在线询价</a></span></li>
                        <li class="clearfix"><span class="col">C1 速成班宝来</span><span class="col">C1</span><span class="col red">￥6300</span><span class="col">周一到周五，连续训练。适合人群，时间充裕，想快速拿本儿者。</span><span class="col"><a class="btn">在线询价</a></span></li>
                        <li class="clearfix"><span class="col">C1 假日班</span><span class="col">C1</span><span class="col red">￥6600</span><span class="col">周六日连续训练。适合人群，只有周六日，双休者。</span><span class="col"><a class="btn">在线询价</a></span></li>
                        <li class="clearfix"><span class="col">E 平日班</span><span class="col">E</span><span class="col red">￥1800</span><span class="col">摩托车报完名后大概一周左右考科目一，科目一考不要走，在这儿约一个基础训练4小时！
                                根据个人时间再预约科目二三1个整天训练，练车从早8点到晚8点，练车当天下午4点后做个测试，测试通过的给您预约科目二三整天考试，考过后您再抽一个半天来考试！
                                科目一考完满7天才能考科目四，
                                所以摩托车拿本周期3周（根据季节的变化可能更快），期间只需要来4次！</span><span class="col"><a class="btn">在线询价</a></span></li>
                    </ul>
                </div>
                <div class="img-dialog hide">
                    <div class="bg"></div>
                    <div class="img-group"><i class="close">×</i>
                        <div class="img" style="background-image:url(https://jiaxiao-image.mucang.cn/jiaxiaozhijia-image/2021/01/19/09/2e516298d73a4f39953bf8200683fbdf.png!2max800);">
                            <div class="loading"></div>
                        </div>
                        <div class="img-manager clearfix"><span class="left"></span><span class="center"><span class="all-img" style="left:px"><span class="img active" style="background-image:url(https://jiaxiao-image.mucang.cn/jiaxiaozhijia-image/2021/01/19/09/2e516298d73a4f39953bf8200683fbdf.png!2max800)"></span><span class="img " style="background-image:url(https://jiaxiao-image.mucang.cn/jiaxiaozhijia-image/2021/01/19/09/7042446906c6430882eedd8da34ccd1b.png!2max800)"></span><span class="img " style="background-image:url(https://jiaxiao-image.mucang.cn/jiaxiaozhijia-image/2021/01/07/16/5706b2714bef4602a503d038a3aa02cf.jpg!2max800)"></span></span></span><span class="right"></span></div>
                    </div>
                </div>
                <div class="com-jiaxiao-photos com-part">
                    <h3 class="part-title">教练教学环境</h3>
                    <div class="images-list clearfix">
                        <div class="img-w"><img src="https://jiaxiao-image.mucang.cn/jiaxiaozhijia-image/2021/01/19/09/2e516298d73a4f39953bf8200683fbdf.png!640.400" alt="教学环境"></div>
                        <div class="img-w"><img src="https://jiaxiao-image.mucang.cn/jiaxiaozhijia-image/2021/01/19/09/7042446906c6430882eedd8da34ccd1b.png!640.400" alt="教学环境"></div>
                        <div class="img-w"><img src="https://jiaxiao-image.mucang.cn/jiaxiaozhijia-image/2021/01/07/16/5706b2714bef4602a503d038a3aa02cf.jpg!640.400" alt="教学环境"></div>
                    </div>
                </div>
                <div class="com-jiaxiao-comment com-part">
                    <h3 class="part-title">学员评价</h3>
                    <div class="top-w">
                        <div class="static-w">
                            <div class="item-left">
                                <div class="score"><span class="big">4.9</span>分</div>
                                <div class="bfb">
                                    <div class="star-w">
                                        <div class="star" style="width: 98.00000000000001%"></div>
                                    </div>
                                </div>
                                <div class="total">共497人评价</div>
                            </div>
                            <div class="fgx"></div>
                            <div class="item-right">
                                <div class="row"><span class="lable">教学态度</span>
                                    <div class="star-w">
                                        <div class="star" style="width: 98.00000000000001%"></div>
                                    </div><span class="score">4.9分</span>
                                </div>
                                <div class="row"><span class="lable">学车收费</span>
                                    <div class="star-w">
                                        <div class="star" style="width: 98.00000000000001%"></div>
                                    </div><span class="score">4.9分</span>
                                </div>
                                <div class="row"><span class="lable">教学技术</span>
                                    <div class="star-w">
                                        <div class="star" style="width: 98.00000000000001%"></div>
                                    </div><span class="score">4.9分</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="list-w">
                        <div class="com-dianping-list com-part">
                            <ul>
                                <li class="clearfix">
                                    <div class="item-left">
                                        <div class="img-w"><img src="https://avatar-user.mucang.cn/user-avatar/default/8b2c0ad47dc31343cad39c4ab08488a7.jpg!100x100"></div>
                                    </div>
                                    <div class="item-right">
                                        <div class="content-top clearfix"><span class="author-name left">临难不恐</span><span class="date right">2021-04-06 03:07</span></div>
                                        <div class="item-static">
                                            <div class="col"><span class="lable">教学态度</span>
                                                <div class="star-w">
                                                    <div class="star" style="width: 100%"></div>
                                                </div><span class="score">5分</span>
                                            </div>
                                            <div class="col"><span class="lable">学车收费</span>
                                                <div class="star-w">
                                                    <div class="star" style="width: 100%"></div>
                                                </div><span class="score">5分</span>
                                            </div>
                                            <div class="col"><span class="lable">教学技术</span>
                                                <div class="star-w">
                                                    <div class="star" style="width: 100%"></div>
                                                </div><span class="score">5分</span>
                                            </div>
                                        </div>
                                        <div class="content">这里约课是交过费的？老师</div>
                                        <div class="content-bottom clearfix"></div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="btn-more-bar"><a>更多评价</a></div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.header-background-gray {
    color: #f5f5f5
}

.block-item>.more-btn,
.load-more-btn {
    display: block;
    height: .8rem;
    line-height: .8rem;
    font-size: 18px;
    font-family: "Microsoft YaHei", "Hiragino Sans GB", "Hiragino Sans GB W3", "Helvetica Neue", Helvetica, Arial, sans-serif;
    background-color: #f5f5f5;
    text-align: center;
    color: #666
}

.t-a-c {
    text-align: center
}

.com-topic-detail>.detail-right>.bottom-bar,
.com-topic-detail>.detail-right>.content .pre,
.com-topic-detail>.detail-right>.top-bar {
    font-family: "Microsoft YaHei", "Hiragino Sans GB", "Hiragino Sans GB W3", "Helvetica Neue", Helvetica, Arial, sans-serif
}

.com-topic-detail,
.com-topic-detail>.detail-right>.bottom-bar>.huifu+.huifu {
    border-right: 1px solid #e9e9e9
}

.com-topic-detail {
    border-left: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;
    background-color: #f5f5f5
}

.com-topic-detail>.detail-left {
    float: left;
    width: 220px;
    padding: 30px 0 10px
}

.com-topic-detail>.detail-right {
    margin-left: 220px;
    padding: 0 20px;
    background-color: #fff
}

.com-topic-detail>.detail-right>.top-bar {
    height: 40px;
    line-height: 40px;
    color: #999;
    font-size: 12px
}

.com-topic-detail>.detail-right>.top-bar>.publish-time {
    float: left
}

.com-topic-detail>.detail-right>.top-bar>.share {
    float: left;
    margin-left: 30px
}

.com-topic-detail>.detail-right>.top-bar>.share .inner {
    display: inline-block;
    margin-top: -3px;
    vertical-align: middle
}

.com-topic-detail>.detail-right>.top-bar>.statis {
    float: right
}

.com-topic-detail>.detail-right>.content {
    min-height: 100px;
    padding-top: 10px;
    line-height: 1.5
}

.com-topic-detail>.detail-right>.content p {
    line-height: 28px
}

.com-topic-detail>.detail-right>.content .pre {
    word-wrap: break-word;
    font-size: 18px
}

.com-topic-detail>.detail-right>.content pre.pre {
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap
}

.com-topic-detail>.detail-right>.content>.content-img .img-wrap {
    width: auto;
    max-width: 700px;
    margin: 10px auto;
    text-align: center
}

.com-topic-detail>.detail-right>.content img {
    margin: 10px auto;
    max-width: 700px
}

.com-topic-detail>.detail-right>.button-bar {
    padding: 50px 0 0;
    text-align: center
}

.com-topic-detail>.detail-right>.button-bar>button {
    width: 150px;
    height: 40px;
    margin: 0 10px;
    color: #fff
}

.com-topic-detail>.detail-right>.button-bar>.zan {
    background-color: #7eb4ed
}

.com-topic-detail>.detail-right>.button-bar>.reply {
    background-color: #EE4653
}

.com-topic-detail>.detail-right>.detail-footer {
    padding: 10px 0 0;
    text-align: center;
    color: #999
}

.com-topic-detail>.detail-right>.detail-footer>.zan-wrap {
    display: inline-block;
    vertical-align: middle
}

.com-topic-detail>.detail-right>.detail-footer>.zan-wrap>.author-list {
    display: inline-block;
    max-width: 500px;
    vertical-align: middle;
    color: #7eb4ed;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    line-clamp: 1
}

.com-topic-detail>.detail-right>.detail-footer>.share {
    display: inline-block;
    vertical-align: middle;
    margin-left: 30px
}

.com-topic-detail>.detail-right>.detail-footer>.share .inner {
    display: inline-block;
    margin-top: -3px;
    vertical-align: middle
}

.com-topic-detail>.detail-right>.detail-relative-wrap {
    padding: 30px 0 10px
}

.com-topic-detail>.detail-right>.bottom-bar {
    padding: 10px 0 15px;
    font-size: 12px;
    line-height: 1
}

.com-topic-detail>.detail-right>.bottom-bar>.tuijian {
    float: left;
    color: #999
}

.com-topic-detail>.detail-right>.bottom-bar>.tuijian:hover {
    color: #EE4653
}

.com-topic-detail>.detail-right>.bottom-bar>.huifu {
    float: right;
    padding: 0 6px;
    cursor: pointer
}

.com-author>.address,
.com-author>.statis>div>.p2 {
    font-family: "Microsoft YaHei", "Hiragino Sans GB", "Hiragino Sans GB W3", "Helvetica Neue", Helvetica, Arial, sans-serif
}

.com-author>.address>p,
.com-author>.name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.com-author {
    width: 150px;
    margin: 0 auto
}

.com-author>.img-wrap {
    position: relative;
    width: 100px;
    height: 100px;
    margin: auto;
    overflow: hidden
}

.com-author>.img-wrap>.louzhu-tip {
    position: absolute;
    top: 0;
    right: 0;
    width: 48px;
    height: 48px;
    background: url("../../assets/files/13f544edd312e3a4fafdabcb4b8029d5.png") right top no-repeat;
    background-size: 100%
}

.com-author>.img-wrap img {
    display: block;
    width: 100%;
    height: auto;
    border: 4px solid #fff
}

.com-author>.name {
    padding: 10px 0;
    text-align: center;
    font-weight: 700;
    -webkit-line-clamp: 1;
    line-clamp: 1
}

.com-author>.statis {
    padding: 10px 0
}

.com-author>.statis>div {
    display: inline-block;
    width: 33.3%;
    vertical-align: top;
    text-align: center
}

.com-author>.statis>div>.p1 {
    color: #EE4653;
    line-height: 1.5
}

.com-author>.statis>div>.p2 {
    color: #666;
    font-size: 14px
}

.com-author>.statis>div+div {
    border-left: 1px solid #999
}

.com-author>.address {
    padding-top: 5px;
    padding-left: 8px;
    color: #999;
    line-height: 1.5;
    font-size: 12px
}

.com-author>.address>p {
    -webkit-line-clamp: 1;
    line-clamp: 1
}

.com-author .bar {
    padding-top: 12px
}

.com-author .bar>button {
    display: inline-block;
    width: 100%;
    line-height: 38px;
    border: 1px solid #e9e9e9;
    background-color: #fff
}

.com-author .bar>button:hover {
    color: #EE4653
}

.com-topic-vote>.car-img-wrap {
    position: relative;
    margin: 30px auto 10px;
    max-width: 580px;
    text-align: center
}

.com-topic-vote>.car-img-wrap>.car {
    display: inline-block;
    margin: 0 40px;
    width: 210px;
    vertical-align: top
}

.com-topic-vote>.car-img-wrap>.car>.car-img {
    width: 210px;
    height: 140px;
    margin: 0;
    overflow: hidden;
    background-size: 100%
}

.com-topic-vote>.car-img-wrap>.car>.car-img .car-name {
    line-height: 1.5px;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    line-clamp: 1
}

.com-topic-vote>.car-img-wrap>.car>.car-img.transform {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg)
}

.com-topic-vote>.car-img-wrap>.pk-icon {
    position: absolute;
    width: 80px;
    height: 90px;
    top: 50%;
    left: 50%;
    margin-top: -45px;
    margin-left: -40px;
    background: url("../../assets/files/04df8406d2efc5ccf104e3134fc5b8c7.png") no-repeat;
    background-size: 100%
}

.com-topic-vote>.car-img-wrap>.pk-icon3 {
    margin-top: -70px
}

.com-topic-vote>.statis {
    padding-top: 10px;
    color: #999;
    text-align: center
}

.com-topic-vote>.car-bfb-wrap {
    width: 500px;
    margin: auto
}

.com-topic-vote>.car-bfb-wrap>.row {
    height: 40px;
    line-height: 40px
}

.com-topic-vote>.car-bfb-wrap>.row>.col {
    display: inline-block;
    vertical-align: middle
}

.com-topic-vote>.car-bfb-wrap>.row>.col1 {
    width: 13%;
    font-weight: 700;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    line-clamp: 1
}

.com-topic-vote>.car-bfb-wrap>.row>.col2 {
    width: 65%;
    padding: 10px
}

.com-topic-vote>.car-bfb-wrap>.row>.col2 .tiao1 {
    width: 100%;
    height: 6px;
    background-color: #f5f5f5;
    border-radius: 3px
}

.com-topic-vote>.car-bfb-wrap>.row>.col2 .tiao2 {
    height: 100%;
    background-color: #EE4653;
    border-radius: 3px
}

.com-topic-vote>.car-bfb-wrap>.row>.col3 {
    width: 7%;
    text-align: center;
    color: #EE4653
}

.com-topic-vote>.car-bfb-wrap>.row>.col4 {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    background: url("../../assets/files/58d9eeace7a650f77dd383e47255390e.png") center no-repeat;
    background-size: 100%;
    cursor: pointer;
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg)
}

.com-topic-vote>.car-bfb-wrap2 {
    width: 400px;
    margin: auto;
    padding-bottom: 10px
}

.com-topic-vote>.car-bfb-wrap2>.row>.col1 {
    float: left;
    width: 40px;
    height: 40px;
    background: url("../../assets/files/58d9eeace7a650f77dd383e47255390e.png") no-repeat;
    background-size: 100%;
    cursor: pointer
}

.com-topic-vote>.car-bfb-wrap2>.row>.col2 {
    position: relative;
    margin: 15px 48px 0
}

.com-topic-vote>.car-bfb-wrap2>.row>.col2>.bar1 {
    float: left;
    background-color: #EE4653;
    height: 8px;
    border-radius: 16px;
    margin-left: -1px
}

.com-topic-vote>.car-bfb-wrap2>.row>.col2>.bar2 {
    float: right;
    background-color: #7eb4ed;
    height: 8px;
    border-radius: 16px;
    margin-right: -1px
}

.com-topic-vote>.car-bfb-wrap2>.row>.col2>.bfb-count1 {
    position: absolute;
    left: 0;
    bottom: -6px;
    color: #EE4653
}

.com-topic-vote>.car-bfb-wrap2>.row>.col2>.bfb-count2 {
    position: absolute;
    right: 0;
    bottom: -6px;
    color: #7eb4ed
}

.com-topic-vote>.car-bfb-wrap2>.row>.col3 {
    float: right;
    width: 40px;
    height: 40px;
    background: url("../../assets/files/c16b130dea2edb8a6ca62a22efc35d91.png") no-repeat;
    background-size: 100%;
    cursor: pointer
}

.com-topic-help>.tags-wrap>.budget-tags,
.com-topic-help>.tags-wrap>label {
    vertical-align: top;
    display: inline-block
}

.com-topic-help>.tags-wrap {
    padding-top: 10px
}

.com-topic-help>.tags-wrap>label {
    width: 50px;
    color: #999
}

.com-topic-help>.tags-wrap>.need-tags {
    display: inline-block;
    vertical-align: middle;
    margin-top: -3px
}

.com-topic-help>.tags-wrap>.need-tags>span {
    display: inline-block;
    line-height: 1.4;
    margin: 5px;
    padding: 0 5px;
    border: 1px solid;
    border-radius: 8px;
    color: #7eb4ed
}

.com-topic-video>.dialog-video-box>.content-box>.btn-bar>a,
.com-topic-video>.dialog-video-box>.content-box>.content-text {
    font-size: 18px;
    font-family: "Microsoft YaHei", "Hiragino Sans GB", "Hiragino Sans GB W3", "Helvetica Neue", Helvetica, Arial, sans-serif
}

.com-topic-video {
    width: 580px;
    margin: 13px auto
}

.com-topic-video video {
    display: block;
    width: 100%;
    height: auto
}

.com-topic-video>.dialog-video-box {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, .7);
    z-index: 1000
}

.com-topic-video>.dialog-video-box>.content-box {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 325px;
    height: 171.6px;
    margin-top: -84.5px;
    margin-left: -162.5px;
    background: #fff;
    border-radius: 6.5px;
    padding: 13px 0
}

.com-topic-video>.dialog-video-box>.content-box>.content-text {
    line-height: 32.5px;
    border-bottom: 1.3px solid #e9e9e9;
    padding: 13px 13px 26px 52px
}

.com-topic-video>.dialog-video-box>.content-box>.btn-bar {
    text-align: center
}

.com-topic-video>.dialog-video-box>.content-box>.btn-bar>a {
    display: inline-block;
    line-height: 52px;
    width: 50%;
    text-align: center
}

.com-topic-video>.dialog-video-box>.content-box>.btn-bar>a:first-child {
    border-right: 1.3px solid #e9e9e9
}

.com-topic-video>.iphone-bg-wrap {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: transparent
}

.com-topic-audio .audio-wrap {
    display: -webkit-box
}

.com-topic-audio .audio-wrap .voice-bg {
    display: block;
    width: 13px;
    height: 18px;
    background: url("../../assets/files/5a3ec94c5c563089587f5ebb44983c1d.png") left center no-repeat;
    background-size: 100% 100%
}

.com-topic-audio .audio-wrap .audio-bg {
    display: block;
    width: 93px;
    height: 28px;
    padding: 5px 0 0 12px;
    background-size: 13px 18px;
    background-color: #8cdffe;
    border-radius: 5px
}

.com-topic-audio .audio-wrap .audio-scd {
    display: block;
    line-height: 30px;
    color: #333;
    font-size: 14px;
    font-weight: 700;
    margin-left: 12px;
    font-family: microsoft yahei
}

@-webkit-keyframes zoomIn {
    0% {
        background: url("../../assets/files/9f6c57d097e320b65591edf29e2c083d.png") center center no-repeat;
        background-size: 100% 100%
    }

    50% {
        background: url("../../assets/files/2b7c181271a4a9db31594fc070e282b5.png") center center no-repeat;
        background-size: 100% 100%
    }

    100% {
        background: url("../../assets/files/5a3ec94c5c563089587f5ebb44983c1d.png") center center no-repeat;
        background-size: 100% 100%
    }
}

@keyframes zoomIn {
    0% {
        background: url("../../assets/files/9f6c57d097e320b65591edf29e2c083d.png") center center no-repeat;
        background-size: 100% 100%
    }

    50% {
        background: url("../../assets/files/2b7c181271a4a9db31594fc070e282b5.png") center center no-repeat;
        background-size: 100% 100%
    }

    100% {
        background: url("../../assets/files/5a3ec94c5c563089587f5ebb44983c1d.png") center center no-repeat;
        background-size: 100% 100%
    }
}

.com-topic-audio .zoomIn {
    -webkit-animation: zoomIn 1s infinite both;
    animation: zoomIn 1s infinite both
}

.com-topic-audio>.audio-wrap {
    margin-top: 20px;
    margin-bottom: 20px
}

.com-topic-audio>.audio-wrap>.audio-bg {
    height: 38px;
    cursor: pointer
}

.com-topic-audio>.audio-wrap>.audio-bg .voice-bg {
    margin: 5px 0 0 12px
}

.com-topic-audio>.audio-wrap>.audio-scd {
    line-height: 38px
}

.com-topic-work .work-container {
    height: 100%;
    position: relative;
    padding: 0
}

.com-topic-work .work-container ul {
    border: 1px solid #EEE
}

.com-topic-work .work-container li {
    display: -webkit-box;
    border-bottom: 1px solid #EEE
}

.com-topic-work .work-container li.first {
    padding: 9.28px 9.28px 9.28px 5.8px
}

.com-topic-work .work-container li.first a {
    display: -webkit-box;
    -webkit-box-flex: 1;
    color: #333;
    text-decoration: none
}

.com-topic-work .work-container li.first img {
    display: block;
    width: auto;
    height: 53px;
    margin: 0
}

.com-topic-work .work-container li.first .car {
    -webkit-box-flex: 1;
    font-size: 15.08px;
    line-height: 53.36px;
    padding-left: 17.4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 5.8px
}

.com-topic-work .work-container li.first .arrow {
    width: 10.44px;
    background: url("../../assets/files/6a3eb8a10a1a27d7764874cfd4cbe819.png") right center no-repeat;
    background-size: 100%
}

.com-topic-work .work-container li div.title {
    color: #333;
    text-align: center;
    padding: 16.24px 13.92px;
    width: 25%;
    font-size: 16.24px;
    line-height: 20.88px;
    border-right: 1px solid #EEE;
    display: flex;
    align-items: center;
    justify-content: center
}

.com-topic-work .work-container li div.desc {
    color: #333;
    text-align: center;
    padding: 16.24px 13.92px;
    -webkit-box-flex: 1;
    font-size: 15.08px;
    line-height: 20.88px
}

.com-topic-work .work-container .content {
    margin-top: 17.4px
}

.com-topic-work .work-container .content header {
    font-size: 17.4px;
    line-height: 34.8px
}

.com-topic-work .work-container .content p {
    color: #999;
    font-size: 15.08px;
    line-height: 23.2px
}

.com-topic-work .work-container .content img {
    display: block;
    width: 100%;
    margin: 11.6px 0
}

.com-topic-work pre {
    white-space: pre-wrap;
    word-wrap: break-word
}

.com-detail-content-quote>.row {
    display: block;
    margin: 20px 0 10px;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 4px
}

.com-detail-content-quote>.row>.img-w {
    display: inline-block;
    vertical-align: middle;
    width: 102px;
    height: 70px;
    overflow: hidden
}

.com-detail-content-quote>.row>.img-w img {
    display: block;
    width: 100%;
    height: auto;
    border: none;
    margin: 0 !important
}

.com-detail-content-quote>.row>.content-text {
    display: inline-block;
    vertical-align: middle;
    margin-left: 20px;
    width: 760px;
    line-height: 1.4;
    text-decoration: underline
}

.com-topic-koubei .kb-container {
    height: 100%;
    position: relative;
    padding: 0
}

.com-topic-koubei .kb-report-wrap {
    height: 243.6px;
    display: -webkit-box;
    position: relative;
    -webkit-box-align: end
}

.com-topic-koubei .kb-report {
    position: relative;
    height: 203px
}

.com-topic-koubei .kb-report .report-bg {
    position: absolute;
    height: 174px;
    width: 100%;
    top: 0;
    right: 0;
    left: 0
}

.com-topic-koubei .kb-report .report-bg div {
    margin-top: 34.8px;
    border-top: 1px solid rgba(0, 0, 0, .05)
}

.com-topic-koubei .kb-report .report-col {
    border-top: 1px solid rgba(0, 0, 0, .05);
    border-bottom: 1px solid rgba(0, 0, 0, .05);
    height: 174px;
    display: -webkit-box;
    position: relative;
    -webkit-box-align: end
}

.com-topic-koubei .kb-report .report-col .col-item {
    position: relative;
    -webkit-box-flex: 1;
    margin: 0 25px;
    height: 65%;
    background: -webkit-linear-gradient(top, #7eb4ed 0, #7eb4ed 100%);
    border-radius: 6.96px 6.96px 0 0
}

.com-topic-koubei .kb-report .report-col .col-item span {
    position: absolute;
    top: -23.2px;
    left: -4.64px;
    color: rgba(0, 0, 0, .4);
    min-width: 23.2px;
    text-align: center
}

.com-topic-koubei .kb-report .report-label {
    display: -webkit-box
}

.com-topic-koubei .kb-report .report-label span {
    display: block;
    -webkit-box-flex: 1;
    text-align: center;
    font-size: 13.92px;
    color: rgba(0, 0, 0, .4);
    line-height: 17.4px;
    padding: 11.6px 0 0
}

.com-topic-koubei .kb-report .report-label span:last-child {
    -webkit-box-flex: .5
}

.com-topic-koubei .kb-car-series {
    height: 47.56px;
    line-height: 47.56px;
    padding: 0 17.4px;
    margin-top: 41.76px;
    border: 1px solid rgba(0, 0, 0, .05);
    font-size: 16.24px;
    color: #353D51;
    font-weight: 700
}

.com-topic-koubei .kb-car-series a {
    text-decoration: none;
    color: #666
}

.com-topic-koubei .kb-car-series p {
    line-height: 47px !important;
    padding-right: 18.56px;
    background: url("../../assets/files/d75e153a8c105438f64aea20e2245bfa.png") right center no-repeat;
    background-size: 10.44px 16.24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.com-topic-koubei .kb-car-info {
    padding: 0 17.4px;
    background: #F7F7F7;
    margin-top: 13.92px
}

.com-topic-koubei .kb-car-info p {
    display: -webkit-box;
    padding: 11.6px 0 10.44px;
    border-bottom: 1px solid rgba(0, 0, 0, .04)
}

.com-topic-koubei .kb-car-info p span {
    font-size: 16.24px;
    line-height: 23.2px;
    display: block;
    width: 50%;
    color: #666
}

.com-topic-koubei .kb-car-info p span.single {
    width: 100%
}

.com-topic-koubei .kb-car-info p span i {
    font-size: 13.92px
}

.com-topic-koubei .kb-car-comm {
    display: -webkit-box
}

.com-topic-koubei .kb-car-comm div {
    padding: 4.64px 20.88px 0 0
}

.com-topic-koubei .kb-car-comm div label {
    display: block;
    padding: 20.88px 0 0;
    font-size: 13.92px;
    line-height: 16.24px
}

.com-topic-koubei .kb-car-comm p {
    font-size: 17.4px;
    color: #666;
    line-height: 24.36px;
    word-break: break-all;
    -webkit-box-flex: 1
}

.com-topic-koubei .kb-car-merit {
    margin-top: 30.16px
}

.com-topic-koubei .kb-car-merit div label {
    background: url("../../assets/files/142379d94c3a4ddf1f22dfd3fc592ed5.png") top center no-repeat;
    background-size: 18.56px 18.56px;
    color: #FFAB49
}

.com-topic-koubei .kb-car-defect {
    padding: 16.24px 0 30.16px;
    border-bottom: 1px solid rgba(0, 0, 0, .05)
}

.com-topic-koubei .kb-car-defect div label {
    background: url("../../assets/files/22f96ddf4319d6de9e292cc95f1911c8.png") top center no-repeat;
    background-size: 18.56px 18.56px;
    color: #3DA7FF
}

.com-topic-koubei .kb-car-intro {
    margin-top: 27.84px
}

.com-topic-koubei .kb-car-intro .intro-hd label {
    font-size: 17.4px;
    color: #333;
    line-height: 23.2px;
    font-weight: 700
}

.com-topic-koubei .kb-car-intro>p {
    display: inline-block;
    vertical-align: top
}

.com-topic-koubei .kb-car-intro .intro-hd span {
    font-size: 16.24px;
    line-height: 23.2px;
    color: #999
}

.com-topic-koubei .kb-car-intro .intro-con {
    padding-left: 4.64px;
    font-size: 17.4px;
    color: #666;
    line-height: 24.36px
}

.com-topic-koubei .kb-img-list {
    overflow: hidden;
    width: 100%;
    margin-top: 27.84px;
    margin-bottom: 18.56px
}

.com-topic-koubei .kb-img-list img {
    display: block;
    width: 100%
}

.com-topic-koubei .kb-report {
    width: 500px;
    margin: 0 auto
}

.com-topic-comment>ul>li,
.com-topic-comment>ul>li>.detail-right>.bottom-bar>.huifu+.huifu {
    border-right: 1px solid #e9e9e9
}

.com-topic-comment>ul>li {
    border-left: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;
    background-color: #f5f5f5
}

.com-topic-comment>ul>li>.fg {
    height: 4px;
    background-color: #e9e9e9
}

.com-topic-comment>ul>li>.detail-left {
    float: left;
    width: 220px;
    padding: 30px 0 10px
}

.com-topic-comment>ul>li>.detail-right {
    position: relative;
    min-height: 290px;
    margin-left: 220px;
    padding: 0 20px 50px;
    background-color: #fff
}

.com-topic-comment>ul>li>.detail-right>.top-bar {
    height: 40px;
    line-height: 40px;
    color: #999;
    font-size: 12px;
    font-family: "Microsoft YaHei", "Hiragino Sans GB", "Hiragino Sans GB W3", "Helvetica Neue", Helvetica, Arial, sans-serif
}

.com-topic-comment>ul>li>.detail-right>.content>.content-text,
.com-topic-comment>ul>li>.detail-right>.zuijia:before {
    font-size: 18px;
    font-family: "Microsoft YaHei", "Hiragino Sans GB", "Hiragino Sans GB W3", "Helvetica Neue", Helvetica, Arial, sans-serif
}

.com-topic-comment>ul>li>.detail-right>.top-bar>.publish-time {
    float: left
}

.com-topic-comment>ul>li>.detail-right>.top-bar>.footer-name {
    float: right
}

.com-topic-comment>ul>li>.detail-right>.top-bar>.share {
    float: left;
    margin-left: 30px
}

.com-topic-comment>ul>li>.detail-right>.top-bar>.share .inner {
    display: inline-block;
    margin-top: -3px;
    vertical-align: middle
}

.com-topic-comment>ul>li>.detail-right>.top-bar>.statis {
    float: right
}

.com-topic-comment>ul>li>.detail-right>.content {
    padding-top: 10px;
    line-height: 1.5
}

.com-topic-comment>ul>li>.detail-right>.content p {
    line-height: 28px
}

.com-topic-comment>ul>li>.detail-right>.content>.content-img .img-wrap {
    width: auto;
    max-width: 700px;
    margin: 10px auto;
    text-align: center
}

.com-topic-comment>ul>li>.detail-right>.content img {
    margin: 10px auto;
    max-width: 700px
}

.com-topic-comment>ul>li>.detail-right>.zuijia {
    background-color: #f5f5f5;
    padding: 0 10px 10px
}

.com-topic-comment>ul>li>.detail-right>.zuijia:before {
    display: block;
    height: 30px;
    margin-bottom: 10px;
    padding-left: 50px;
    background: url("../../assets/files/ba1d1c2af42dc8104387f0cdadb5e151.png") left center no-repeat;
    background-size: auto 100%;
    content: '最佳答案';
    line-height: 30px;
    color: #EE4653
}

.com-topic-comment>ul>li>.detail-right>.quote-wrap {
    min-height: auto;
    margin: 10px 40px;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #e9e9e9
}

.com-topic-comment>ul>li>.detail-right>.bottom-bar {
    position: absolute;
    bottom: 0;
    left: 10px;
    right: 10px;
    padding: 10px 0 15px;
    font-size: 12px;
    font-family: "Microsoft YaHei", "Hiragino Sans GB", "Hiragino Sans GB W3", "Helvetica Neue", Helvetica, Arial, sans-serif;
    line-height: 1
}

.com-topic-comment>ul>li>.detail-right>.bottom-bar>.tuijian {
    float: left;
    max-width: 400px;
    color: #999;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    line-clamp: 1
}

.com-topic-comment>ul>li>.detail-right>.bottom-bar>.huifu:hover,
.com-topic-comment>ul>li>.detail-right>.bottom-bar>.tuijian:hover {
    color: #EE4653
}

.com-topic-comment>ul>li>.detail-right>.bottom-bar>.huifu {
    float: right;
    padding: 0 6px;
    cursor: pointer
}

.com-topic-comment>.footer-pager {
    padding: 20px 0;
    text-align: left
}

.com-detail-footer-items>.com-part>.part-title,
.com-detail-footer-items>.com-part>.part-title .more {
    font-family: "Microsoft YaHei", "Hiragino Sans GB", "Hiragino Sans GB W3", "Helvetica Neue", Helvetica, Arial, sans-serif
}

.com-detail-footer-items>.com-part {
    margin: 40px 0
}

.com-detail-footer-items>.com-part>.part-title {
    line-height: 22px;
    margin-bottom: 10px;
    padding-bottom: 8px;
    font-size: 18px;
    font-weight: 400;
    border-bottom: 1px solid #e9e9e9
}

.com-detail-footer-items>.com-part>.part-title .more {
    float: right;
    margin-right: 10px;
    font-size: 14px;
    color: #999
}

.com-detail-footer-items>.com-part>.part-title .more:hover {
    color: #37B5F8
}

.com-side-com-list-col3>.list-w {
    margin: 0 -20px
}

.com-side-com-list-col3>.list-w>li {
    float: left;
    width: 33.3%;
    margin: 10px 0;
    padding: 0 20px;
    color: #666
}

.com-side-com-list-col3>.list-w>li>a {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.com-side-com-list-col3>.list-w>li>a>.dian {
    padding-right: 5px
}

.common-paging>ul>li.pre {
    background: url("../../assets/files/405fee75f912878a3a64cb3185510e6f.png") center no-repeat
}

.common-paging>ul>li.next {
    background: url("../../assets/files/0d6cea75496cac7ee3a629e5369cb9d7.png") center no-repeat
}

.common-paging>ul>li.disabled.pre {
    background: url("../../assets/files/e564bffffcfb26636113cb226064784e.png") center no-repeat
}

.common-paging>ul>li.disabled.next {
    background: url("../../assets/files/6fcaa9f4e4f732405a58f524004e8e92.png") center no-repeat
}

.detail>.topic-pager>.right>button,
.detail>.topic-title,
.detail>.topic-title>.only-author {
    font-family: "Microsoft YaHei", "Hiragino Sans GB", "Hiragino Sans GB W3", "Helvetica Neue", Helvetica, Arial, sans-serif
}

.detail {
    margin-top: 20px
}

.detail>.topic-pager {
    margin-left: -6px;
    padding-bottom: 10px
}

.detail>.topic-pager>.right>button {
    margin-top: 8px;
    margin-left: 6px;
    width: 80px;
    line-height: 30px;
    background-color: #EE4653;
    color: #fff;
    font-size: 14px
}

.detail>.topic-pager>.right>button+button {
    background-color: #f90
}

.detail>.topic-pager.bottom {
    padding-top: 10px;
    padding-bottom: 0
}

.detail>.topic-title {
    position: relative;
    line-height: 38px;
    padding-left: 20px;
    border: 1px solid #e9e9e9;
    font-size: 16px;
    font-weight: 700
}

.detail>.topic-title>.shoucang {
    display: inline-block;
    position: relative;
    top: -1px;
    width: 42px;
    height: 15px;
    margin-left: 5px;
    background: url("../../assets/files/5630be4dd152aa14fbe2283e975b1ac0.png");
    vertical-align: middle;
    cursor: pointer
}

.detail>.topic-title>.only-author {
    float: right;
    margin-right: 10px;
    font-size: 14px;
    font-weight: 400
}

.detail>.topic-title>h1 {
    display: inline-block;
    font-size: inherit;
    font-weight: inherit
}

.detail>.topic-hot {
    padding-top: 30px
}

.detail .content-link {
    color: #7eb4ed
}

.detail .content-link:hover {
    color: #7eb4ed;
    text-decoration: underline
}

.detail .com-jiaxiao-list-random {
    width: auto
}

.detail .com-jiaxiao-list-random>.list-w {
    margin-left: -150px
}

.detail .com-jiaxiao-list-random>.list-w>li {
    padding-left: 150px;
    margin: 0
}

.layout-user {
    height: 34px;
    line-height: 20px;
    padding: 7px 0;
    background: #f5f5f5;
    color: #999
}

.layout-user>.content .left-link>li .tiku-mune ul>li a.active,
.layout-user>.content .left-link>li .tiku-mune ul>li a:hover,
.layout-user>.content .right-link .active,
.layout-user>.content .right-link a:hover {
    color: #37B5F8
}

.layout-user>.content .city {
    padding-left: 15px;
    background: url("../../assets/files/1272afef1a66936bae7d785d24e4b319.png") left center no-repeat;
    cursor: pointer
}

.com-top-user-city .city {
    padding-left: 15px;
    background: url("../../assets/files/eae9b23cf7983349005bb5e7e771478a.png") left center no-repeat;
    cursor: pointer
}

.common-city-selection>.list>ul>li>.item>ul {
    padding-left: 58px
}

.common-city-selection-dialog>.close {
    width: 25px;
    height: 25px;
    background: url("../../assets/files/a7474b44821b980050ffead66d07dd38.png") center no-repeat;
    right: 0;
    top: 0;
    cursor: pointer;
    position: absolute;
    display: block
}

.layout-navigation>.navigation-top>.logo-w .logo {
    display: block;
    width: 207px;
    height: 100%;
    overflow: hidden;
    vertical-align: middle;
    text-align: left;
    background: url("../../assets/files/faab6749bd917811e688ac5d5bc2b782.png") left center no-repeat
}

.layout-navigation>.navigation-top .search-w button {
    position: absolute;
    top: 7px;
    right: 5px;
    height: 29px;
    width: 30px;
    background: url("../../assets/files/988d5f1c9d31781584e82d0ecad4f582.png") center no-repeat;
    border: none
}

.layout-navigation>.navigation-content>.nav-w>li {
    display: inline-block;
    padding: 0;
    vertical-align: top;
    width: 107px;
    text-align: center
}

.layout-navigation2>.logo-w .logo {
    display: block;
    width: 207px;
    height: 100%;
    overflow: hidden;
    vertical-align: middle;
    text-align: left;
    background: url("../../assets/files/5309392905c9eaaccdc170f078756f78.png") left center no-repeat
}

.layout-navigation2>.nav-w .search-w button {
    position: absolute;
    top: 2px;
    right: 5px;
    height: 29px;
    width: 30px;
    background: url("../../assets/files/71073a79ef0afc287a4030ad69dfcdce.png") center no-repeat;
    border: none
}

.layout-footer>.content .footer3 {
    height: 108px;
    width: 1200px;
    padding-top: 25px;
    margin: 0 auto;
    padding-bottom: 18px;
    overflow: hidden
}

.layout-footer>.content .footer3 .left {
    float: left;
    margin-left: 180px;
    padding-right: 70px;
    border-right: 1px solid #666;
    width: 232px;
    height: 108px
}

.layout-footer>.content .footer3 .left a {
    display: block;
    padding-top: 15px;
    height: 56px
}

.layout-footer>.content .footer3 .right {
    float: left;
    height: 108px;
    margin-left: 70px
}

.com-dialog-growUp .growUp {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 6rem;
    height: 6rem;
    border-radius: 8px;
    background: url("../../assets/files/fe1205c93682f15045fb62830fb73483.png") center .34rem no-repeat #fff;
    background-size: 3.72rem
}

.com-enroll-pannel .input-box>form>.row .submit-btn.loading {
    background: url("../../assets/files/2e15855e2d3012e2f442c66c952800a9.gif") center no-repeat;
    background-size: contain;
    cursor: default;
    color: transparent
}

.com-dialog-enroll-freephone .dialog-regist-box>.row-tip {
    margin-top: -5px;
    color: #999;
    text-align: center
}

.com-dialog-login .dialog-login-box>.tab-content-w .tab-content-login .other-login>.type .qq {
    width: 44px;
    height: 44px;
    background: url("../../assets/files/8f8a1b78796d67a65e2de8379c81b4ee.png") center center no-repeat;
    background-size: 44px 44px;
    display: block;
    margin: 0 auto
}

.com-dialog-login .dialog-login-box>.tab-content-w .tab-content-coach>.login-code>.img {
    background: url("../../assets/files/7d633be84261a709f4f307c4e7dab942.png") left top no-repeat;
    background-size: 255px 48px;
    position: absolute;
    left: 0;
    top: 0;
    width: 255px;
    height: 48px;
    z-index: 2
}

.com-dialog-login .dialog-login-box>.tab-content-w .tab-content-coach>.login-code>.code {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 200px;
    height: 200px;
    background: url("../../assets/files/3476971d4d13a3421c9619ec7ce84e5e.png") center center no-repeat;
    background-size: 174px 174px;
    border: 1px solid #eee
}

.com-login-pannel .input-box>form>.row select {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: url("../../assets/files/4047b1ec9715fd3a9818df3dde9a3cf1.png") right 10px center no-repeat #fff
}

.com-login-pannel .input-box>form>.row .submit-btn.loading {
    background: url("../../assets/files/b0438773813bf9a2eabe20147a3f29e2.gif") center no-repeat;
    background-size: contain;
    cursor: default;
    color: transparent
}

.com-dialog-login-share .dialog-login-box .other-login-type>.qq {
    width: 44px;
    height: 44px;
    background: url("../../assets/files/f4078891d88bdf4b07ebb580f72840c4.png") center center no-repeat;
    background-size: 44px 44px;
    display: block;
    margin: 20px auto 0
}

.com-login-share .loading {
    min-width: 300px;
    min-height: 180px;
    margin: 0 auto;
    background: url("../../assets/files/85f1c93260c1a7335a6e30ce1fe0f5b2.gif") center no-repeat;
    background-size: 100px
}

.com-footer-flex-panel {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 110px;
    z-index: 0
}

.com-footer-flex-panel>.content-box {
    position: relative;
    width: 1200px;
    margin: 0 auto;
    height: 100%;
    z-index: 3
}

.com-footer-flex-panel>.content-box>.flex-close {
    position: absolute;
    top: 45px;
    right: 100px;
    height: 32px;
    width: 32px;
    line-height: 30px;
    border: none;
    background: url("../../assets/files/620b6e8cf3a4e36deba36f5e8f78fbe5.png") center no-repeat;
    font-size: 32px;
    color: #999;
    text-align: center;
    cursor: pointer
}

.com-footer-flex-panel>.content-box>.bg-img {
    position: absolute;
    top: 0;
    left: 90px;
    width: 160px;
    height: 125px;
    background: url("../../assets/files/11d274126fb4a3ce2876037345e1954d.png") center no-repeat;
    background-size: 100%
}

.baidu-search-box input {
    line-height: 24px;
    padding: 0 20px 0 5px;
    background: url("../../assets/files/9b0522d7a24f31d2702e7baa8b575e94.png") right 5px center no-repeat;
    border-color: #37B5F8
}

.com-appdown-dialog>.dialog-box>.dialog-content>.erwm {
    width: 296px;
    height: 296px;
    margin: 20px auto;
    background: url("../../assets/files/dcd41a46d528d96dc110be54407faf44.png") center no-repeat;
    background-size: 80% auto;
    border: 1px solid #e9e9e9;
    border-radius: 6px
}

.com-appdown-dialog>.dialog-box>.btn-dialog-close {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 30px;
    height: 30px;
    background: url("../../assets/files/d381506045f2d8582bdf00c0d5b09d45.jpg") center no-repeat;
    background-size: 40%;
    border: 1px solid #e9e9e9;
    border-radius: 50%;
    cursor: pointer
}

.com-dialog-first-visited2>.content-box>.content {
    height: 30px;
    line-height: 30px;
    padding-left: 25px;
    background: url("../../assets/files/c454c61f8db82676e5a7d815ac0458e5.png") left center no-repeat;
    background-size: 22px 15px;
    font-size: 18px
}

.com-dialog-first-visited3 .visited-content-box>.item-left>.row-city .city-name {
    padding-right: 20px;
    background: url("../../assets/files/ac7a65de254ed4b793fec8e915767497.png") right center no-repeat;
    color: #37B5F8;
    cursor: pointer;
    font-size: 14px
}

.com-dialog-first-visited3 .visited-content-box>.item-left>.row-baomin .select-baomin {
    width: 260px;
    height: 40px;
    line-height: 38px;
    font-size: 14px;
    margin-left: 0;
    padding: 0 10px;
    border: 1px solid #e9e9e9;
    outline: 0;
    -webkit-appearance: none;
    background: url("../../assets/files/b202869db721d36e059a395366ec278e.png") right 10px center no-repeat #fff
}

.com-dialog-first-visited4 .visited-content-box>.item-left>.row-city .city-name {
    padding-right: 20px;
    background: url("../../assets/files/0e4926aeb52a2955cd85b7faeed2aa33.png") right center no-repeat;
    color: #37B5F8;
    cursor: pointer;
    font-size: 14px
}

.com-dialog-first-visited4 .visited-content-box>.item-left>.row-baomin .select-baomin {
    width: 260px;
    height: 40px;
    line-height: 38px;
    font-size: 14px;
    margin-left: 0;
    padding: 0 10px;
    border: 1px solid #e9e9e9;
    outline: 0;
    -webkit-appearance: none;
    background: url("../../assets/files/026013145c714bb61581f2fd4df84a5d.png") right 10px center no-repeat #fff
}

body,
html {
    overflow-x: hidden
}

.container .none-data-block:before {
    display: block;
    content: '';
    width: 100%;
    height: 2.4rem;
    background: url("../../assets/files/12269a28a995a89f8407725fff30cb36.png") center top no-repeat;
    background-size: 2.8rem
}

.container .page-empty-data span:before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 300px;
    background: url("../../assets/files/12269a28a995a89f8407725fff30cb36.png") center 30px no-repeat;
    background-size: 280px
}

.container .no-data {
    margin: 30px;
    background: url("../../assets/files/b12469ee38c879f3d0f59dc3392fe978.png") center top no-repeat;
    background-size: 50px 50px;
    padding-top: 60px;
    text-align: center;
    color: #999
}

.container .star-w-s {
    width: 90px;
    height: 20px;
    margin: 10px 0;
    background: url("../../assets/files/d59d67daf676ffce9e157bd3a0634dee.png") left center no-repeat
}

.container .star-w-s>.bfb {
    height: 100%;
    background: url("../../assets/files/07c954300da670874111fd227ae902fb.png") left center no-repeat
}

.container .star-w-m {
    width: 110px;
    height: 20px;
    margin: 10px 0;
    background: url("../../assets/files/e7754c64750c1cb6b742ea4b10dac05a.png") left center no-repeat
}

.container .star-w-m>.bfb {
    height: 100%;
    background: url("../../assets/files/7c543ae5cb114b37005a31933ba1af4a.png") left center no-repeat
}

.container>.frame-l-r>.no-float {
    float: none;
    width: 100%
}

.container>.frame-l-r>.no-float>.btn-more-bar {
    text-align: center;
    margin: -20px 0 20px
}

.container>.frame-l-r>.no-float>.btn-more-bar a {
    display: inline-block;
    line-height: 30px;
    padding: 0 30px;
    border: 1px solid #e9e9e9
}

.container>.frame-l-r>.no-float>.btn-more-bar a:hover {
    border: 1px solid #37B5F8
}

.com-coach-base-info>.jiaxiao-content {
    padding: 20px;
    border: 1px solid #e9e9e9;
    position: relative
}

.com-coach-base-info>.jiaxiao-content>.img-w {
    display: block;
    width: 170px;
    height: 170px;
    overflow: hidden
}

.com-coach-base-info>.jiaxiao-content>.img-w img {
    display: block;
    width: 170px;
    height: auto
}

.com-coach-base-info>.jiaxiao-content>.img-w mip-img {
    display: inline-block
}

.com-coach-base-info>.jiaxiao-content>.img-w mip-img img {
    min-width: inherit
}

.com-coach-base-info>.jiaxiao-content>.item-mid {
    margin-left: 20px;
    color: #666
}

.com-coach-base-info>.jiaxiao-content>.item-mid a:hover {
    color: #37B5F8
}

.com-coach-base-info>.jiaxiao-content>.item-mid>.top-bar {
    margin-bottom: 8px
}

.com-coach-base-info>.jiaxiao-content>.item-mid>.top-bar>* {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px
}

.com-coach-base-info>.jiaxiao-content>.item-mid>.top-bar>.title {
    line-height: 25px;
    font-size: 20px;
    font-family: "Microsoft YaHei", "Hiragino Sans GB", "Hiragino Sans GB W3", "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin-left: 0
}

.com-coach-base-info>.jiaxiao-content>.item-mid>.top-bar .renzheng {
    width: 52px;
    height: 25px;
    background: url("../../assets/files/7cfb41af5a58e92ca353ca6d65a20efd.png") left center no-repeat
}

.com-coach-base-info>.jiaxiao-content>.item-mid>.top-bar .qianyue {
    line-height: 18px;
    color: #37B5F8;
    border: 1px solid #37B5F8;
    border-radius: 9px;
    padding: 0 5px
}

.com-coach-base-info>.jiaxiao-content>.item-mid>.top-bar .cwxc {
    line-height: 18px;
    color: #EE4653;
    border: 1px solid #EE4653;
    border-radius: 9px;
    padding: 0 5px
}

.com-coach-base-info>.jiaxiao-content>.item-mid>.fenxiang-w {
    margin: 15px 0 0;
    line-height: 27px
}

.com-coach-base-info>.jiaxiao-content>.item-mid>.fenxiang-w .share {
    display: inline-block;
    vertical-align: middle
}

.com-coach-base-info>.jiaxiao-content>.item-mid>.fenxiang-w .share .inner {
    display: inline-block;
    margin-top: -3px;
    vertical-align: middle
}

.com-coach-base-info>.jiaxiao-content>.item-mid>.crumbs {
    margin: 5px auto;
    line-height: 27px
}

.com-coach-base-info>.jiaxiao-content>.item-mid>.crumbs>* {
    display: inline-block
}

.com-coach-base-info>.jiaxiao-content>.item-mid>.crumbs i {
    margin: auto 5px
}

.com-coach-base-info>.jiaxiao-content>.item-mid>.crumbs span {
    color: #999
}

.com-coach-base-info>.jiaxiao-content>.item-mid>.price {
    display: block;
    line-height: 36px;
    font-size: 24px;
    font-weight: 700;
    color: #fd6d24
}

.com-coach-base-info>.jiaxiao-content>.item-mid>.address,
.com-coach-base-info>.jiaxiao-content>.item-mid>.price.no-price {
    font-size: 14px;
    font-family: "Microsoft YaHei", "Hiragino Sans GB", "Hiragino Sans GB W3", "Helvetica Neue", Helvetica, Arial, sans-serif
}

.com-coach-base-info>.jiaxiao-content>.item-mid>.price.no-price {
    font-weight: 400;
    color: #999
}

.com-coach-base-info>.jiaxiao-content>.item-mid>.phone-w {
    margin: 10px 0
}

.com-coach-base-info>.jiaxiao-content>.item-mid>.phone-w>span {
    display: inline-block
}

.com-coach-base-info>.jiaxiao-content>.item-mid>.phone-w a {
    color: #37B5F8
}

.com-coach-base-info>.jiaxiao-content>.item-mid>.address {
    margin: 5px 0;
    color: #999
}

.com-coach-base-info>.jiaxiao-content>.item-mid>.address>span {
    display: inline-block;
    line-height: 30px;
    margin-right: 40px;
    padding-left: 25px
}

.com-coach-base-info>.jiaxiao-content>.item-mid>.address .score-w {
    padding-left: 0
}

.com-coach-base-info>.jiaxiao-content>.item-mid>.address .score-w>.star-w {
    display: inline-block;
    vertical-align: top;
    width: 90px;
    height: 24px;
    background: url("../../assets/files/61a8cc25fa0514c6d1a40dd2f433902e.png") left center no-repeat
}

.com-coach-base-info>.jiaxiao-content>.item-mid>.address .score-w>.star-w>.score {
    width: 0;
    height: 100%;
    background: url("../../assets/files/8d2456fc60cc6126e3d5b5fce83b3e78.png") left center no-repeat
}

.com-coach-base-info>.jiaxiao-content>.item-mid>.address .score-w>.score {
    display: inline-block;
    vertical-align: top;
    height: 24px;
    line-height: 24px;
    margin-left: 10px
}

.com-coach-base-info>.jiaxiao-content>.item-mid>.address .dianp {
    background: url("../../assets/files/6ac0061826ca938f394a056f1e36a06c.png") left center no-repeat
}

.com-coach-base-info>.jiaxiao-content>.item-mid>.address .student {
    background: url("../../assets/files/4fa8cd0c13495ba513405304407690c9.png") left center no-repeat
}

.com-coach-base-info>.jiaxiao-content>.btn-base-enroll {
    position: absolute;
    right: 20px;
    bottom: 20px;
    line-height: 36px;
    padding: 0 20px;
    background-color: #37B5F8;
    border-radius: 4px;
    color: #fff;
    font-size: 16px;
    font-family: "Microsoft YaHei", "Hiragino Sans GB", "Hiragino Sans GB W3", "Helvetica Neue", Helvetica, Arial, sans-serif
}

.com-jiaxiao-nav>ul {
    background-color: #FAFAFA;
    font-size: 16px;
    font-family: "Microsoft YaHei", "Hiragino Sans GB", "Hiragino Sans GB W3", "Helvetica Neue", Helvetica, Arial, sans-serif
}

.com-jiaxiao-nav>ul>li {
    float: left;
    padding-top: 4px
}

.com-jiaxiao-nav>ul>li.active {
    background-color: #37B5F8
}

.com-jiaxiao-nav>ul>li.active>a {
    color: #37B5F8;
    background-color: #fff
}

.com-jiaxiao-nav>ul>li>a {
    display: block;
    line-height: 46px;
    padding: 0 30px
}

.com-jiaxiao-introducea {
    line-height: 24px
}

.com-jiaxiao-introducea>.content,
.com-jiaxiao-introducea>.row {
    margin: 10px 0
}

.com-jiaxiao-introducea>.row>label {
    color: #999;
    padding-right: 10px
}

.com-jiaxiao-price {
    line-height: 24px
}

.com-jiaxiao-price .p3 {
    line-height: 1.2;
    margin: 10px 0
}

.com-jiaxiao-price .list-w {
    display: table;
    width: 100%;
    margin-top: 20px;
    border: 1px solid #e9e9e9;
    border-bottom: none;
    font-size: 14px;
    font-family: "Microsoft YaHei", "Hiragino Sans GB", "Hiragino Sans GB W3", "Helvetica Neue", Helvetica, Arial, sans-serif
}

.com-jiaxiao-price .list-w>li {
    display: table-row;
    line-height: 22px;
    height: 80px
}

.com-jiaxiao-price .list-w>li:first-child {
    background-color: #FAFAFA;
    height: 60px
}

.com-jiaxiao-price .list-w>li .col {
    display: table-cell;
    vertical-align: middle;
    width: 20%;
    border-bottom: 1px solid #e9e9e9;
    padding: 10px 15px 10px 0
}

.com-jiaxiao-price .list-w>li .col:first-child {
    padding-left: 20px
}

.com-jiaxiao-price .list-w>li .col.right {
    text-align: center
}

.com-jiaxiao-price .list-w>li .col.last {
    width: 110px
}

.com-jiaxiao-price .list-w>li .col.small {
    width: 10%
}

.com-jiaxiao-price .list-w>li .col.big {
    width: 15%
}

.com-jiaxiao-price .list-w>li .col .btn {
    display: inline-block;
    width: 97px;
    line-height: 35px;
    background-color: #f90;
    border-radius: 17px;
    text-align: center;
    color: #fff
}

.com-jiaxiao-price .list-w>li .col2 {
    width: auto
}

.com-jiaxiao-photos.com-part {
    margin-bottom: 20px !important
}

.com-jiaxiao-photos .images-list {
    margin-left: -24px
}

.com-jiaxiao-photos .images-list>.img-w {
    float: left;
    margin: 0 0 20px 24px;
    cursor: pointer;
    display: block;
    width: 282px;
    height: 188px;
    overflow: hidden
}

.com-jiaxiao-photos .images-list>.img-w img {
    display: block;
    width: 282px;
    height: auto
}

.com-jiaxiao-photos .images-list>.img-w mip-img {
    display: inline-block
}

.com-jiaxiao-photos .images-list>.img-w mip-img img {
    min-width: inherit
}

.com-jiaxiao-comment .static-w .item-left .score,
.com-jiaxiao-comment .static-w .item-right {
    font-family: "Microsoft YaHei", "Hiragino Sans GB", "Hiragino Sans GB W3", "Helvetica Neue", Helvetica, Arial, sans-serif
}

.com-jiaxiao-comment .static-w {
    height: 240px;
    border: 1px solid #e9e9e9;
    padding-top: 40px;
    text-align: center
}

.com-jiaxiao-comment .static-w .item-left {
    display: inline-block;
    vertical-align: middle;
    width: 400px
}

.com-jiaxiao-comment .static-w .item-left .score {
    line-height: 65px;
    font-size: 16px
}

.com-jiaxiao-comment .static-w .item-left .score .big {
    font-size: 48px
}

.com-jiaxiao-comment .static-w .item-left .bfb {
    height: 30px;
    margin: 5px 0
}

.com-jiaxiao-comment .static-w .item-left .total {
    line-height: 22px;
    margin-top: 20px;
    color: #999
}

.com-jiaxiao-comment .static-w .item-left .star-w {
    width: 151px;
    background: url("../../assets/files/48a578535803246688d99d16c295e11e.png") left center no-repeat;
    background-size: 100%
}

.com-jiaxiao-comment .static-w .item-left .star-w .star {
    background: url("../../assets/files/3d0e488f3ce89bb59607324eaed61723.png") left center no-repeat
}

.com-jiaxiao-comment .static-w .fgx {
    display: inline-block;
    vertical-align: middle;
    width: 1px;
    height: 120px;
    margin-top: 5px;
    background-color: #e9e9e9
}

.com-jiaxiao-comment .static-w .item-right {
    display: inline-block;
    vertical-align: middle;
    width: 400px;
    font-size: 16px
}

.com-jiaxiao-comment .static-w .item-right>.row {
    height: 22px;
    line-height: 22px;
    margin: 20px 0 0 90px;
    text-align: left
}

.com-jiaxiao-comment .static-w .item-right>.row .lable {
    display: inline-block;
    vertical-align: middle;
    width: 80px;
    margin-right: 15px;
    text-align: right
}

.com-jiaxiao-comment .static-w .item-right>.row .score {
    display: inline-block;
    vertical-align: middle;
    margin-left: 15px
}

.com-jiaxiao-comment .star-w {
    display: inline-block;
    vertical-align: top;
    width: 101px;
    height: 100%;
    background: url("../../assets/files/80e2573269e2b3c4c6c4258f4d3de49a.png") left center no-repeat;
    background-size: 100%
}

.com-jiaxiao-comment .star-w .star {
    display: block;
    width: 100%;
    height: 100%;
    background: url("../../assets/files/59259c4b13f9cadd8f0b4ba42ada3f9b.png") left center no-repeat
}

.com-dianping-list>ul>li {
    padding: 25px 0;
    border-bottom: 1px solid #e9e9e9
}

.com-dianping-list>ul>li>.item-left {
    float: left
}

.com-dianping-list>ul>li>.item-left .img-w {
    border-radius: 50%;
    display: block;
    width: 60px;
    height: 60px;
    overflow: hidden
}

.com-dianping-list>ul>li>.item-left .img-w img {
    display: block;
    width: 60px;
    height: auto
}

.com-dianping-list>ul>li>.item-left .img-w mip-img {
    display: inline-block
}

.com-dianping-list>ul>li>.item-left .img-w mip-img img {
    min-width: inherit
}

.com-dianping-list>ul>li>.item-left .floor-name {
    text-align: center;
    margin-top: 10px;
    color: #999
}

.com-dianping-list>ul>li>.item-right {
    margin-left: 80px
}

.com-dianping-list>ul>li>.item-right>.content-top {
    height: 25px;
    line-height: 25px
}

.com-dianping-list>ul>li>.item-right>.content-top .date {
    color: #999
}

.com-dianping-list>ul>li>.item-right>.content-top .author-name {
    font-size: 16px;
    font-family: "Microsoft YaHei", "Hiragino Sans GB", "Hiragino Sans GB W3", "Helvetica Neue", Helvetica, Arial, sans-serif
}

.com-dianping-list>ul>li>.item-right .content,
.com-dianping-list>ul>li>.item-right .item-static {
    font-size: 14px;
    font-family: "Microsoft YaHei", "Hiragino Sans GB", "Hiragino Sans GB W3", "Helvetica Neue", Helvetica, Arial, sans-serif
}

.com-dianping-list>ul>li>.item-right>.content-top .city {
    margin-left: 30px;
    color: #999
}

.com-dianping-list>ul>li>.item-right .item-static .col {
    display: inline-block;
    vertical-align: middle;
    width: 300px;
    height: 22px;
    line-height: 22px;
    margin: 15px auto
}

.com-dianping-list>ul>li>.item-right .item-static .col .lable {
    color: #999;
    padding-right: 15px
}

.com-dianping-list>ul>li>.item-right .item-static .col .score {
    padding-left: 15px
}

.com-dianping-list>ul>li>.item-right .content {
    margin: 10px 0;
    line-height: 28px
}

.com-dianping-list>ul>li>.item-right .content.quote {
    background-color: #f5f5f5;
    border: 1px solid #e9e9e9;
    padding: 10px 25px
}

.com-dianping-list>ul>li>.item-right .content-bottom {
    line-height: 25px
}

.com-dianping-list>ul>li>.item-right .content-bottom .huifu {
    min-width: 16px;
    height: 25px;
    background: url("../../assets/files/2926453bd851419f7d38cfa1794bab3a.png") left center no-repeat;
    background-size: auto 16px;
    padding-left: 20px
}

.com-dianping-list>ul>li>.item-right .content-bottom .zan {
    margin-right: 20px;
    background: url("../../assets/files/feb58db11dda499e22c7eacbad095494.png") left center no-repeat;
    background-size: auto 16px;
    padding-left: 20px
}

.com-dianping-list .star-w {
    display: inline-block;
    vertical-align: top;
    width: 101px;
    height: 100%;
    background: url("../../assets/files/3b6882c0d0d051992448083c8dfc455a.png") left center no-repeat;
    background-size: 100%
}

.com-dianping-list .star-w .star {
    display: block;
    width: 100%;
    height: 100%;
    background: url("../../assets/files/4a20e549891ab4ec7098dae999de9c8d.png") left center no-repeat
}

.img-dialog .bg {
    position: fixed;
    z-index: 98;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #000;
    opacity: .5;
    filter: alpha(opacity=50)
}

.img-dialog .img-group {
    position: fixed;
    z-index: 99;
    background-color: #000;
    width: 820px;
    height: 660px;
    left: 50%;
    top: 50%;
    margin: -330px 0 0 -410px
}

.img-dialog .img-group.min-height {
    top: 10px;
    margin-top: auto
}

.img-dialog .img-group.min-width {
    right: 10px;
    left: auto;
    margin-left: auto
}

.img-dialog .img-group .close {
    width: 24px;
    height: 24px;
    background-color: #ddd;
    text-align: Center;
    line-height: 22px;
    position: absolute;
    right: -12px;
    top: -12px;
    cursor: pointer;
    font-size: 20px;
    border-radius: 12px
}

.img-dialog .img-group>.img {
    margin: 15px 15px 0;
    height: 550px;
    background: center center no-repeat;
    background-size: cover;
    position: relative
}

.img-dialog .img-group>.img .loading {
    width: 64px;
    height: 64px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -32px 0 0 -32px;
    background: url("../../assets/files/a59a9a9028110419c13a1d6993a993bc.gif") center center no-repeat;
    background-size: cover;
    display: none
}

.img-dialog .img-group>.img-manager {
    padding: 15px
}

.img-dialog .img-group>.img-manager .left {
    float: left;
    width: 25px;
    height: 64px;
    cursor: pointer;
    background: url("../../assets/files/1646bbbb648c22acfdcf049b383e7245.png") center center no-repeat #444;
    opacity: .5
}

.img-dialog .img-group>.img-manager .left:hover {
    opacity: 1
}

.img-dialog .img-group>.img-manager .center {
    width: 730px;
    height: 64px;
    overflow: hidden;
    display: inline-block;
    float: left;
    margin: 2px 0 0 8px
}

.img-dialog .img-group>.img-manager .center .all-img {
    width: 10000px;
    display: block;
    position: relative;
    transition: left .3s;
    left: 0
}

.img-dialog .img-group>.img-manager .center .all-img .img {
    width: 80px;
    height: 60px;
    margin-right: 12px;
    overflow: hidden;
    display: inline-block;
    background: center center no-repeat;
    background-size: cover;
    cursor: pointer;
    border: 2px solid transparent
}

.img-dialog .img-group>.img-manager .center .all-img .img.active {
    border: 2px solid #fff
}

.img-dialog .img-group>.img-manager .center .all-img .img:last-child {
    margin-right: 0
}

.img-dialog .img-group>.img-manager .center .all-img .img img {
    width: 80px
}

.img-dialog .img-group>.img-manager .right {
    float: right;
    width: 25px;
    height: 64px;
    cursor: pointer;
    background: url("../../assets/files/37aea8d0e942feff71ee2a3bccc0cc2a.png") center center no-repeat #444;
    opacity: .5
}

.img-dialog .img-group>.img-manager .right:hover {
    opacity: 1
}

@media screen and (max-height:768px) {
    .img-dialog .img-group {
        width: 820px;
        height: 600px;
        left: 50%;
        top: 50%;
        margin: -300px 0 0 -410px
    }

    .img-dialog .img-group>.img {
        margin: 15px 15px 0;
        height: 500px
    }
}
</style>